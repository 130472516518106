@import "./mixins";

:root {
  --color-dark: #2a2a2a;
  --color-yellow: #ffca2f;
  --body-bg: #fff;
  --text-color: #000;
  --link-color: #0e76a8;
  --border-color: #2a2a2a;

  //   Dark mode
  --dark-bg: #2a2a2a;

  --dark-text-color: #fff;
  --dark-link-color: #ffca2f;
  --dark-border-color: #fff;
}

// DEFAULT GLOBAL STYLE
$font-stack: "Montserrat", sans-serif;
* {
  margin: 0;
  box-sizing: border-box;
  font-family: $font-stack;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}
.flex {
  display: flex;
  gap: 1rem;
}
ul {
  list-style: none;
}

.wrapper {
  background: var(--body-bg);
  min-height: 100vh;
  color: var(--text-color);
}

.grid {
  display: grid;
  gap: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: bolder;
}
//apply dark mode
.dark-mode-checkbox:checked + .wrapper {
  --body-bg: var(--dark-bg);
  --text-color: var(--dark-text-color);
  --link-color: var(--dark-link-color);
  --border-color: var(--dark-border-color);
}

body {
  line-height: 1.5;
}

// =====Dark Mode
#darkMode {
  display: none;
  position: fixed;
  top: 6rem;
  left: 1rem;
}
.dark-mode-toggle {
  position: fixed;
  top: 6rem;
  left: 2rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.5);
    transition: all 0.5s;
  }
}

// =====header
.primary-header {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.288);
  position: sticky;
  top: 0;
  z-index: 99;
  background: var(--body-bg);
  color: var(--text-color);

  .logo {
    justify-content: center;
    align-items: center;
    gap: 3rem;
    position: relative;

    div {
      &:nth-child(1) {
        h1 {
          font-size: 1.8rem;
        }
      }
      &:nth-child(2) {
        h1 {
          font-size: 1rem;
          //   font-weight: bolder;
        }
      }
    }

    .line {
      &::before {
        content: "";
        background: var(--color-yellow);
        height: 60%;
        width: 4px;
        left: 40%;
        position: absolute;
      }
    }
  }

  .right {
    #check,
    .menu-icon {
      display: none;
    }
  }

  .navigation {
    padding: 0;
    margin: 0;
    gap: 2.5rem;

    li {
      a {
        &:hover {
          color: var(--color-yellow);
          transition: all 0.5s;
        }
      }
    }
  }
}

// media query for tabelt design for header
@include tablet() {
  .primary-header {
    .right {
      .menu-icon {
        display: block;
      }

      .navigation {
        flex-direction: column;
        width: 15rem;
        height: 100vh;
        background: var(--body-bg);
        position: fixed;
        left: 100%;
        top: 4.7rem;
        transition: 0.5s ease-in;
        box-shadow: 0 0 0 1px rgba(226, 226, 226, 0.199);
        li {
          padding-left: 1rem;
        }
      }

      #check:checked ~ ul {
        left: calc(100% - 15rem);
      }
    }
  }
}

// #header,
// #skills,
// #projects,
// // #about,
// #contact {
//   height: 7rem;
// }
//section1 hero
.section1 {
  padding-top: 10rem;

  .hero {
  
    background: #8FD29D;
    background: linear-gradient(270deg, #8FD29D, #31B7CE);
    margin: 2rem auto 5rem auto;
    width: 90vw;
    height:90vh;
    padding: 0 2rem;
    border-radius: 100px;
    //   padding-top: 10rem;

    grid-template-areas: "left right";

    .left {
      grid-area: left;
      margin-top: -4rem;
      justify-content: center;
      align-items: center;

      img {
        width:50%;
        height: 100%;
      }
    }
    .right {
      grid-area: right;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.8rem;

      div {
        flex-direction: column;
        gap: 0.6rem;

        .name {
          font-size: 4rem;
          font-weight: bold;
          color: white;
        }
        .tag {
          color: white;
          font-size: 2.2rem;
        }
      }
      button {
        border: none;
        padding: 0.8rem 1.2rem;
        border-radius: 2rem;
        cursor: pointer;
        background: var(--color-dark);
        color: white;
        margin: 1rem 0;


      
        }
        a {
            &:hover{
                transform: scale(1.5);
                color: blue;
                transition: transform color 0.5s;
            }
          i {
            color: var(--color-yellow);
          }
          
        }
       
      }
    }
  }


// media query for hero

@include tablet(){
    .section1 {
        padding-top: 5rem;
      
        .hero {
            background: #8FD29D;
            background: linear-gradient(270deg, #8FD29D, #31B7CE);
          margin: 0 2rem 5rem auto;
          width: 80%;
          padding: 0 2rem;
          height: 70vh;
          border-radius: 10px;
          //   padding-top: 10rem;
      
          grid-template-areas: 
          "left left"
          "right right";
      
          .left {
            grid-area: left;
            margin-top: 0rem;
            justify-content: center;
            align-items: center;
            height: 35vh;
            
         img {
              height: 100%;
              width: 70%;
              
            }
          }
          .right {
            padding: 4rem;
            grid-area: right;
            flex-direction: column;
            justify-content: center;
            height: 50%;
            // align-items: flex-start;
           
      
            div {
              flex-direction: column;
             
      
              .name {
                font-size: 3rem;
                font-weight: bold;
                color: white;
                margin-bottom: 2px;
              }
              .tag {
                
                color: white;
                font-size: 1rem;
              }
            }
            button {
              border: none;
              padding: 0.8rem 1.2rem;
              border-radius: 2rem;
              cursor: pointer;
              background: var(--color-dark);
              color: white;
              margin: 1rem 0;
              a {
                i {
                  color: var(--color-yellow);
                }
              }
            }
          }
        }
      }
}

// section2  banner
.section2 {
  margin-bottom: 7rem;
  .info-container {
    max-width: 80%;
    margin: auto;
    padding: 2rem;
    min-height: 7rem;
    border: 2px solid var(--border-color);
    border-radius: 100px;

    justify-content: center;
    align-items: center;

    .info-lists {
      width: 100%;
      color: var(--text-color);
      justify-content: space-around;
      align-items: center;

      .info-content {
        .icon-container {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background: var(--color-yellow);

          justify-content: center;
          align-items: center;
          color: white;

          i {
            font-size: 1.4rem;
          }
        }
        span {
          h5 {
            font-weight: bolder;
            letter-spacing: 2px;
          }
          p {
            color: var(--color-yellow);
          }
        }
      }
      .info-divider {
        width: 2px;
        height: 35px;
        background: grey;
      }
    }
  }
}

// media query for banner
@include tablet(){
    .section2 {
        margin-bottom: 7rem;
        .info-container {
          max-width: 50%;
          margin: auto;
          padding: 2rem;
          
          border: 2px solid var(--border-color);
          border-radius: 60px;
      
          justify-content: center;
          align-items: center;
          
      
          .info-lists {
            width: 100%;
            color: var(--text-color);
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            
      
            .info-content {
               
              .icon-container {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                background: var(--color-yellow);
      
                justify-content: center;
                align-items: center;
                color: white;
      
                i {
                  font-size: 1.4rem;
                }
              }
              span {
                h5 {
                  font-weight: bolder;
                  letter-spacing: 2px;
                }
                p {
                  color: var(--color-yellow);
                }
              }
            }
            .info-divider {
             display: none;
            }
          }
        }
      }
}
// secton 3 skills
.section3 {
 
  padding: 3rem auto;
  padding-top: 7rem;

  .skills-container {
    width: 80%;
    justify-content: center;
    gap: 8rem;
    margin: auto;

    div {
      flex-direction: column;
      align-items: center;
      font-weight: bolder;

      i {
        font-size: 3rem;
      }
    }
  }
}
// mediaquery for skills section
@include tablet()
     {
        .section3 {
           margin-top: -10rem;
            .skills-container {
              width: 80%;
              justify-content: center;
              gap: 5px;
              margin: auto;
              
              justify-content: space-around;
          
              div {
                flex-direction: column;
                align-items: center;
                font-weight: bolder;
          
                i {
                  font-size: 3rem;
                }
              }
            }
          } 
    }
// secton 4 projects
.section4 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;

  .projects-container {
    width: 100%;
    padding: 3rem 2rem 0 2rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2.5rem;

    .project-card {
      flex-direction: column;
      box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      .top {
        img {
          width: 100%;
          height: 250px;
          border-radius: 10px 10px 0 0;
        }
      }
      .bottom {
        padding: 2rem;
        p {
          &:nth-child(1) {
            font-weight: bolder;
          }
        }
      }
    }
  }
}

// === section5 about
.section5 {
  flex-direction: column;
  justify-self: center;
  align-items: center;
  padding-top: 7rem;

  .about-container {
    padding: 3rem;

    .left {
      width: 50%;
      padding: 0 2rem;

      img {
        width: 100%;
      }
    }

    .right {
      flex-direction: column;
      width: 50%;
      gap: 2rem;
      padding: 0 2rem;

      h2 {
        font-size: 3.5rem;
      }
      p {
        &:nth-child(3) {
          font-weight: 600;
        }
      }
      div {
        flex-direction: column;
        .tag {
          font-weight: 600;
        }
        div {
          flex-direction: row;
          gap: 2rem;
          span {
            padding: 0.5rem 0.7rem;
            border-radius: 1rem;

            &:hover {
              background: rgba(128, 128, 128, 0.709);
              cursor: pointer;
              color: white;
              box-shadow: 0 0 10px grey;
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}

@include tablet(){

    .section5 {
        flex-direction: column;
        justify-self: center;
        align-items: center;
        padding-top: 7rem;
      
        .about-container {
          padding: 3rem;
          flex-direction: column;
      
          .left {
            width: 100%;
            padding: 0 2rem;
      
            img {
              width: 80%;
              height:50vh;
            }
          }
      
          .right {
            flex-direction: column;
            width: 100%;
            gap: 2rem;
            padding: 0 2rem;
      
            h2 {
              font-size: 3.5rem;
            }
            p {
              &:nth-child(3) {
                font-weight: 600;
                justify-content: center;
              }
            }
            div {
              flex-direction: column;
              .tag {
                font-weight: 600;
              }
              div {
                flex-direction: row;
                gap: 2rem;
                span {
                  padding: 0.5rem 0.7rem;
                  border-radius: 1rem;
      
                  &:hover {
                    background: rgba(128, 128, 128, 0.709);
                    cursor: pointer;
                    color: white;
                    box-shadow: 0 0 10px grey;
                    transition: all 0.5s;
                  }
                }
              }
            }
          }
        }
      }
}
// =======section
.section6 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  .socials {
    padding: 2rem;
    width: 100%;
    justify-content: space-between;
    a{
      font-size: 4rem;
      &:nth-child(1) {
        color: blue;
      }

      &:nth-child(2) {
        color: #6e5494;
      }
      &:nth-child(3) {
        color: rgba(0, 221, 255, 0.793);
      }

      &:nth-child(4) {
        color: blue;
      }
    }
  }

  .email-section {
    background-color: var(--border-color);
    padding: 1rem 2rem;
    border-radius: 100px;
    color: var(--body-bg);

    .email {
      display: flex;
      justify-content: center;
      align-items: center;
      //   background: var(--dark-border-color);
      .send {
        width: 3rem;
        height: 3rem;
        background: var(--color-yellow);
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Footer
footer {
  background: #000;
  color: white;
  padding: 4rem;
  text-align: center;
  margin-top: 3rem;

  .footer-container {
    justify-content: space-around;
    margin-bottom: 4rem;
    .links,
    .socials {
      flex-direction: column;
      h3 {
        text-transform: uppercase;
      }
      ul {
        flex-direction: column;
        padding: 0;

        li {
          a {
            &:hover {
              color: var(--color-yellow);
              transition: all 0.5s;
            }
          }
        }
      }
    }
  }
}

.last{
    .page-top-scroll{
        display: none;
    }
    label{
        a{
            i{
                font-size: 3rem;
                background: var(--color-yellow) ;
                border-radius: 50%;
                padding: 1rem;
                bottom: 10px;
               
                right: 5px;
                position:fixed;
                transition: all 0.5s;
               
                &:hover{
                   color: #0e76a8;
                   background: #000;
                   transform: scale(1.2cm);
                }
            }
        }
    }
.page-top-scroll:checked + .now {}
    }
